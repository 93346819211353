<template>
  <div class="thirdParthLogin">
    <div class="other">
      —— &nbsp;&nbsp;{{ $t("addLogin.otherlogin") }}&nbsp;&nbsp; ——
    </div>
    <div class="qita">
      <div>
        <img
          v-if="NODE_ENV !== 'intl'"
          src="@/assets/img/weChat.png"
          alt=""
          @click="create_loginWeChat"
        />
        <img
          src="@/assets/img/dingtalk.png"
          alt=""
          @click="create_loginDingTalk"
        />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/../config/index";

export default {
  data() {
    return {};
  },
  computed: {
    NODE_ENV() {
      return process.env.NODE_ENV;
    },
  },
  mounted() {
    if (
      window.location.pathname == "/phoneloginp" ||
      window.location.pathname == "/phonelogine" ||
      window.location.pathname == "/phoneloginr"
    ) {
      localStorage.setItem("loginfunction", "phone");
    }
  },
  methods: {
    // 进入钉钉二维码页面
    create_loginDingTalk() {
      const inviteMark = this.$route.query.inviteMark
        ? this.$route.query.inviteMark
        : "";
      localStorage.setItem("thirdparty", "dingTalk");
      const href = `https://login.dingtalk.com/oauth2/auth?redirect_uri=${
        config.authentication_url + "loginDingTalk"
      }&response_type=code&client_id=suitedx3epqal6m6ormp6&scope=openid corpid&state=${inviteMark}&prompt=consent`;
      window.open(href, "_blank");
    },

    // 进入微信二维码页面
    create_loginWeChat() {
      const inviteMark = this.$route.query.inviteMark
        ? this.$route.query.inviteMark
        : "";
      localStorage.setItem("thirdparty", "weChat");
      const url = encodeURIComponent(config.authentication_url + "loginWeChat");
      const href = `https://open.weixin.qq.com/connect/qrconnect?appid=wx5842d2773eb38044&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=${inviteMark}`;
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.thirdParthLogin {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  width: 220px;

  .qita {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    img {
      width: 48px;
      cursor: pointer;
      margin-right: 28px;
    }

    img:nth-child(1) {
      margin-left: 28px;
    }
  }
}
</style>
