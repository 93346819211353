<template>
  <div style="display: flex;align-items: center;flex-direction: column;">
    <div class="topChange">
      <span class="active">{{ $t('addLogin.loginEmail.psd') }}</span>
      <router-link
        to="/login/login_email"
        class="clear"
        style="display: flex; align-items: center"
      >
        <span class="noactive">{{ $t('addLogin.loginEmail.email2') }}</span>
      </router-link>
    </div>
    <div class="main">
      <div class="inputbox">
        <div slot="reference" class="i_emial">
          <input
            id="email"
            v-model="email"
            type="eamil"
            class="email all"
            :placeholder="$t('addLogin.forgetPsd.place1')"
          /><i class="iconfont icon-email frontIcon"></i>
        </div>

        <div class="i_code">
          <input
            id="password"
            v-model="password"
            type="password"
            class="num all"
            :placeholder="$t('addLogin.loginPsd.place')"
          />
          <i class="iconfont icon-locker frontIcon"></i>
          <i v-if="showPw" class="iconfont icon-pwo backIcon" @click="changePwVisible"></i>
          <i v-if="!showPw" class="iconfont icon-pwc backIcon" @click="changePwVisible"></i>
        </div>
        <div class="forget">
          <router-link
            to="/login/forget_psd"
            class="clear"
            style="display: flex; align-items: center"
          >
            <p>{{ $t("addLogin.loginPsd.forget") }}</p>
          </router-link>
        </div>
        <div class="lobtn">
          <el-button
            class="login_goto"
            type="primary"
            :loading="btnLoading"
            @click="handleLogin"
            >{{ $t("addLogin.loginEmail.login") }}</el-button
          >
            <!-- <div v-if="node_env !== 'privatization'" class="r1">
              <router-link
                to="/login/login_email"
                class="clear"
                style="display: flex; align-items: center"
              >
                <p>{{ $t("addLogin.loginEmail.email2") }}</p>
              </router-link>
            </div> -->
          <div v-if="node_env !== 'privatization'" class="r2 router">
            <span>{{$t("addLogin.forgetPsd.title4")}}&nbsp;</span>
            <router-link
              to="/login/register"
              class="clear"
              style="display: flex; align-items: center"
            >
              <p>{{ $t("addLogin.register.register") }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <third-party-login></third-party-login>
    </div>
  </div>
  
</template>

<script>
import { request_joinedProjects } from "@/network/personal/index.js";
import { sessionSave, sessionRead, sessionRemove } from "@/utils/session";
import { mapActions, mapMutations } from "vuex";
import { getWatermark } from "@/network/watermask";
import thirdPartyLogin from '@/components/thirdPartyLogin'

export default {
  components: {
    thirdPartyLogin,
  },
  data() {
    return {
      btnLoading: false,
      eye: false,
      email: "",
      password: "",
      vercode: "",
      issend: false,
      timeo: 60,
      showPw: false
    };
  },
  computed: {
    node_env() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    ...mapMutations(["SET_WATERMASK"]),
    ...mapActions([
      "setTestPlan",
      "setVersion",
      "setSprint",
      "setBaseLine",
      "setUser",
      "setExecutionStatus",
      "setFileType",
      "setProjectUserList",
      "setStatus",
    ]),
    changePwVisible() {
      if (document.getElementById("password").type == "password") {
        document.getElementById("password").type = "text";
        this.showPw = true;
      } else {
        document.getElementById("password").type = "password";
        this.showPw = false;
      }
    },
    set_release_obj(pid) {
      let i = pid ? pid : this.get_pid();
      this.setTestPlan(i);
      this.setVersion(i);
      this.setSprint(i);
      this.setBaseLine(i);
      this.setExecutionStatus(i);
      this.setFileType(i);
      this.setProjectUserList(i);
      this.setStatus(i);
    },
    check_email() {
      let emailRegExp = /^[\.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailRegExp.test(this.email)) {
        this.$message.warning(this.$t("addLogin.forgetPsd.message2"));
        return false;
      }
      return true;
    },

    handleLogin() {
      if (this.password.length < 1) {
        this.$message.warning(this.$t("addLogin.loginPsd.place"));
        return false;
      }
      if (!this.check_email()) {
        return false;
      }

      let ruleForm = {
        password: this.password,
        email: this.email,
      };
      this.btnLoading = true;
      this.$store
        .dispatch("Login_password", ruleForm)
        .then((flag) => {
          if (flag === 1) {
            request_joinedProjects()
              .then((res) => {
                this.$store.commit("set_joined_projects", res);
                this.set_release_obj(res[0].projectId);
                sessionSave("p_id", res[0].projectId);
                const backURL = sessionRead("backURL");
                if (
                  backURL === "null" ||
                  backURL === "" ||
                  typeof backURL === "undefined"
                ) {
                  this.$router.push({ path: `/home` });
                } else {
                  sessionRemove("backURL");
                  this.$router.push({ path: backURL });
                }
              })
              .catch(() => {
                this.btnLoading = false;
              });
            getWatermark().then((res) => {
              this.SET_WATERMASK({
                waterMaskSwitch: res.switchFlag ? true : false,
                waterMaskValue: res.content ? res.content : "",
              })
              this.btnLoading = false;
            });
          } else {
            this.btnLoading = false;
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.topChange{
  width: 400px;
  display: flex;
  span{
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    height: 30px;
    width: 200px;
  }
  .active{
    border-bottom: 1px solid rgba(47, 100, 181, 1);
    color:rgba(47, 100, 181, 1);
  }
  .noactive{
    border-bottom: 1px solid rgba(195, 195, 195, 1);
  }
}
.main {
  background: url();
}
.tip {
  width: 370px;
  height: 60px;
  color: white;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; // 画布是10,topbar组件是99,关联弹窗遮>=2012
  transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.clear {
  color: inherit;
  user-select: none;
}

.main {
  width: 100%;
  display: grid;
  grid-template-columns: 100% 60%;
  // border: 2px solid white;
  border-radius: 20px;
  .inputbox {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    .lobtn {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .login_goto {
        font-size: 16px;
        font-weight: 700;
        word-spacing: 10px;
        border-radius: 10px;
        width: 100%;
        margin: 0px 10px;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .router {
        height: 100%;
        text-align: start;
        font-size: 14px;
        display: flex;
        margin-top: 30px;
        align-self: start;
        span{
          color: rgba(137, 138, 140, 1);
        }
        p {
          margin: 0;
          color: rgba(47, 100, 181, 1);
        }
      }
    }
    
    .yzm {
      cursor: pointer;
      display: flex;
      justify-content: end;
      align-items: center;
      height: 30px;
      border: 1px solid rgba(48, 100, 143, 1);
      padding-right: 4px;
      p {
        font-size: 18px;
        margin: 0;
      }
    }

    .yzm1 {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 30px;
      border: 1px solid rgba(48, 100, 143, 1);

      //   padding-left: 10px;
      p {
        letter-spacing: 2px;
        font-size: 18px;
        margin: 0;
        margin-left: 2px;
      }
    }

    .i_emial {
      // margin-top: -50px;
      // margin-bottom: 100px;
      position: relative;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
    }
    .i_code {
      margin-top: 30px;

      position: relative;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
    }
    .forget {
      width: 400px;
      display: flex;
      justify-content: flex-end;
      // margin-bottom: 100px;
      margin-right: 20px;
      word-spacing: 5px;
      font-size: 14px;
      margin-top: 10px;
    }
    .email {
      width: 350px;
    }
    .email {
      padding-left: 40px;
      padding-right: 40px;
      width: 400px;
      height: 50px;
      border: 1px solid rgba(195, 195, 195, 1);
      border-radius: 6px;
      outline: none;
    }
    .num {
      padding-left: 40px;
      padding-right: 40px;
      width: 400px;
      height: 50px;
      border: 1px solid rgba(195, 195, 195, 1);
      border-radius: 6px;
      outline: none;
    }

    .all {
      font-size: 16px;
    }

    input::-webkit-input-placeholder {
      padding-left: 15px;
      font-size: 16px;
      color:rgba(195, 195, 195, 1);
      letter-spacing: 2px;
    }
  }
  .frontIcon{
    position: absolute;
    cursor: pointer;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 25px auto;
    margin-left: 11px;
    transform: translateY(-8px);
  }
  .backIcon{
    position: absolute;
    cursor: pointer;
    right: 19px;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 25px auto;
    transform: translateY(-8px);
  }
}
// .r2{
//     cursor: not-allowed !important;
//   pointer-events:none !important;
// }
</style>
