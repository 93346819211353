import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
export function request_allProjects() {
  return request({
    url: `/${serviceConfig['project-service']}/allProjects`,
    method: 'get',
  })
}
export function change_project_inf(query, projectId) {

  return request({
    url: `/${serviceConfig['project-service']}/project/${projectId}/baseInfo`,
    method: 'post',
    data: query,
  })
}
export function request_allPageProjects(params) {
  return request({
    url: `/${serviceConfig['project-service']}/allPageFuzzyProjects`,
    params,
    method: 'get',
  })
}
export function request_allDeletedPageProjects(params) {
  return request({
    url: `/${serviceConfig['project-service']}/allDeletedFuzzyPageProjects`,
    params,
    method: 'get',
  })
}


export function reset_nickname(data) {

  return request({
    url: `/${serviceConfig['account-service']}/userInfo`,
    method: 'put',
    data,
  })
}
/**添加项目 */
export function request_projects_add(createProjectDto) {
  const data = createProjectDto
  return request({
    url: `/${serviceConfig['project-service']}/project`,
    method: 'post',
    data: data,
  })
}
/*上传项目头像 */

export function upload_project_avatar(data) {
  return request({
    url: `/${serviceConfig['project-service']}/project/avatar/file`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    async: false,
  })

}
/**添加项目成员 */
export function projects_add_member(addProjectMemberDto) {
  const data = addProjectMemberDto
  return request({
    url: `/${serviceConfig['project-service']}/projectMember`,
    method: 'post',
    data: data,
  })
}
/**获取我加入的项目 */
export function request_joinedProjects() {
  return request({
    url: `/${serviceConfig['project-service']}/joinedProjects`,
    method: 'get',
  })
}


export function remove_Project(projectId) {
  return request({
    url: `/${serviceConfig['project-service']}/project/${projectId}`,
    method: 'DELETE',
  })

}


export function get_removeProject() {
  //  获取删除的项目
  return request({
    url: `/${serviceConfig['project-service']}/allDeletedProjects`,
    method: 'get',
  })

}

export function removefover_Project(projectId) {
  //  永久删除项目
  return request({
    url: `/${serviceConfig['project-service']}/project/destroy/${projectId}`,
    method: 'DELETE',

  })

}


export function recover_removeProject(projectId) {
  //  还原删除的项目
  return request({
    url: `/${serviceConfig['project-service']}/projectRestore/${projectId}`,
    method: 'put',

  })

}

export function allSystemManager() {
  //  获取租户下的管理员
  return request({
    url: `/${serviceConfig['account-service']}/tenant/allSystemManager`,
    method: 'get',
  })
}
export function getProjectProcesses(deleteProcessId) {
  //  获取删除流程信息
  return request({
    url: `/${serviceConfig['project-service']}/deleteProjectProcesses/${deleteProcessId}`,
    method: 'get',
  })
}
export function startProcess(projectId) {
  //  发起项目流程
  return request({
    url: `/${serviceConfig['project-service']}/deleteProjects/${projectId}/startProcess`,
    method: 'post',
  })
}
export function endProcess(params) {
  //  结束项目流程
  return request({
    url: `/${serviceConfig['project-service']}/deleteProjects/${params.projectId}/endProcess`,
    method: 'post',
    params: {
      deleteProcessId: params.deleteProcessId,
      agreed: params.agreed
    }
  })
}